<script>
export default {
    name: 'RtIcon',
    functional: true,
    props: {
        variant: {
            type: String,
            default: 'solid',
        },
        icon: {
            required: true,
            type: [String, Object],
        },
        size: {
            type: [String, Number],
            default: '14',
        },
        badge: {
            type: [String, Object, Number],
            default: null,
        },
        badgeClasses: {
            type: [String, Object, Array],
            default: 'badge-primary',
        },
    },
    render(h, { props, data }) {
        // Create an array to store the child elements
        const children = []

        // Conditionally add the badge element if badge prop is provided
        if (props.badge) {
            const badgeEl = h('span', { staticClass: 'badge badge-up badge-pill', class: props.badgeClasses }, [props.badge])
            children.push(badgeEl)
        }

        // Return span with children and size
        return h('span', {
            staticClass: 'position-relative',
            class: [`fa-${props.variant}`, `fa-${props.icon}`],
            style: {
                fontSize: typeof props.size === 'number' ? `${props.size}px` : props.size
            }
        }, children)
    },
}
</script>
