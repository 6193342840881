import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import useAuth from '@/auth/useAuth';
import {API_KEY, BASE_URL, GOOGLE_MAPS_API_KEY} from "@core/utils/constants";


// Global Components§
import './global-components'

// Custom plugins
import '@axios';


// Icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
library.add(fab);
Vue.component('font-awesome-icon', FontAwesomeIcon);


// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/vue-select';
import '@/libs/sweet-alerts';

import moment from 'moment';
import axios from "axios";
import { get } from 'lodash';
import { i18n } from '@/libs/i18n';

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

//google maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: GOOGLE_MAPS_API_KEY,
        libraries: 'places',
    },
});


// Phone input
// import { MazBtn,MazPhoneNumberInput } from 'maz-ui'

// Vue.use(MazBtn)
// Vue.use(MazPhoneNumberInput)

// Crisp chat
// import CrispChat from '@dansmaculotte/vue-crisp-chat'

// Vue.use(CrispChat, {
//   websiteId: 'd5178d94-66d5-4d4a-889b-a2a408c505ff', //TODO get website id
//   disabled: false,
//   hideOnLoad: false
// })


// Signature Pad
// import VueSignaturePad from 'vue-signature-pad';

// Vue.use(VueSignaturePad);

import initializeAdditionalExtensions from "@client/client_extensions.js";
initializeAdditionalExtensions();





import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);


import { useUtils as useI18nUtils } from '@core/libs/i18n';

Vue.config.productionTip = false;
const { i18n: i18nFunc } = useI18nUtils();
Vue.mixin({
  methods: {
    changeLang(whichLocale = 'fr') {
      // Check if the locale messages for the desired language are already loaded
      if (i18n.messages[whichLocale] && Object.keys(i18n.messages[whichLocale]).length > 1) {
        i18n.locale = whichLocale;
        this.langLoaded = true;
        return; // Exit the method early
      }

      store.commit('app/REQUEST_PENDING');
      axios.get(`${BASE_URL}/locales?lang=` + whichLocale, {
        headers: {
          'Api-Key': API_KEY,
        },
      }).then(({ data }) => {
        store.commit('app/REQUEST_DONE');
        const locales = Object.keys(data);

        locales.forEach((locale) => {
          i18n.setLocaleMessage(locale, data[locale]);
        });

        i18n.locale = whichLocale;
        this.langLoaded = true;

      }).catch(ex => {
        store.commit('app/REQUEST_DONE');
        console.log(ex);
      });
    },
    i18nT: (str) => {
      return i18nFunc(str);
    },
    successMessage: (res) => {
      return (
          get(res, 'data.message') || get(res, 'message') || this.i18nT(`Succès`)
      );
    },
    iCan: (module, perm) => {
      return useAuth.iCan(module, perm)
    },
    truncateStr: function(data, num) {
      const reqdString = data
          .split(' ')
          .slice(0, num)
          .join(' ')
      return data.length > reqdString.length ? reqdString + '...' : reqdString
    },
    errorMessage: (err) => {
      return (
          get(err, 'response.data.message') || get(err, 'response.data["error_message"]') || i18nFunc(`An error has occurred`)
      );
    },
  },
});

Vue.filter('date', (value) => {
  // TODO set Date Format when api is available
  if(!value) return '';
  const dateFormat = useAuth.getDateFormat() !== (null || 'null') ? useAuth.getDateFormat() : 'DD/MM/YYYY';
  const formatted = moment(new Date(value)).format(dateFormat);
  return formatted;
});

Vue.filter('capFirst', (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
})

Vue.filter('money', (value) => {
  return parseFloat(value).toFixed(2); // 2 decimal places
});

Vue.filter('currency', (value) => {
  const currencyDict = {
    '€': 'EUR',
    '$': 'USD',
    '£': 'GBP'
  };
  if(currencyDict[value.currency]) {
    value.currency = currencyDict[value.currency];
  }
  const currency = value.currency || 'EUR';
  const formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(value.amount);
  return formatted;
});

Vue.filter('time', (value) => {
  const dateFormat = 'HH:mm';
  const formatted = moment(new Date(value)).format(dateFormat);
  return formatted;
});


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
