import { getCurrentInstance } from "@vue/composition-api";
let proxy;

export const i18n = (key) => {
  try {
    if (!proxy) {
      proxy = getCurrentInstance().proxy;
    }
    return proxy.$t
        ? process.env.VUE_APP_USE_PLACEHOLDER_UNTRANSLATED == "true"
            ? proxy.$te(key)
                ? proxy.$t(key)
                : `----${key}-----`
            : proxy.$t(key)
        : key;
  } catch (ex) {
    console.log("i18n not yet loaded");
  }
};

export const i18nK = (key) => {
  try {
    if (!proxy) {
      proxy = getCurrentInstance().proxy;
    }
    return proxy.$t
        ? process.env.VUE_APP_USE_PLACEHOLDER_UNTRANSLATED == "true"
            ? proxy.$te(key)
                ? proxy.$t(key)
                : `----${key}-----`
            : proxy.$t(key)
        : key;
  } catch (ex) {
    console.log("i18nK not yet loaded");
  }
};

export const _ = null;
