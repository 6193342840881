import Vue from 'vue'

// axios
import axios from 'axios'
import useAuth from '@/auth/useAuth'
import store from '@/store'

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	baseURL: process.env.VUE_APP_API_URL
	// timeout: 1000,
	// headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.defaults.showLoader = true;

axiosIns.interceptors.request.use(
	(config) => {
		if (config.showLoader) {
			store.commit('app/REQUEST_PENDING')
		}
		return config
	},
	(error) => {
		if (error.config.showLoader) {
			store.commit('app/REQUEST_DONE')
		}
		return Promise.reject(error)
	}
)

axiosIns.interceptors.response.use(
	(response) => {
		if (response.config.showLoader) {
			store.commit('app/REQUEST_DONE')
		}
		return response
	},
	(error) => {
		const { response } = error
		if (response.config.showLoader) {
			store.commit('app/REQUEST_DONE')
		}

		if (response && response.status === 401) {
			localStorage.removeItem(useAuth.authConfig.storageTokenKeyName)
			localStorage.removeItem(useAuth.authConfig.storageUserTypeKeyName)
			localStorage.removeItem(useAuth.authConfig.storageRefreshTokenKeyName)
			localStorage.removeItem(useAuth.authConfig.storageCurWorkspaceKeyName)
			localStorage.removeItem(useAuth.authConfig.storageWorkspacesKeyName)
			localStorage.removeItem(useAuth.authConfig.storageProfileKeyName)
			localStorage.removeItem(useAuth.authConfig.storageSoftwaresKeyName)
			localStorage.removeItem(useAuth.authConfig.storageCurrentSoftwareKeyName)

			// Remove userData from localStorage
			localStorage.removeItem('userData')

			// Redirect to login page
			window.location.href = '/login'
		}
		return Promise.reject(error);
	}
)

Vue.prototype.$http = axiosIns

export default axiosIns
