import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    requestsPending: 0,
    shallShowOverlay: false,
    localUserData: {},
    msgRefreshIntervalId: null,
    mailboxCounts: [],
    globalMessage: '',
    globalMessageType: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    globalMessage(state) {
      return state.globalMessage
    },
    globalMessageType(state) {
      return state.globalMessageType
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    REQUEST_PENDING(state) {
      if (state.requestsPending === 0) {
        this.commit('app/TOGGLE_OVERLAY', true)
      }
      state.requestsPending++
    },
    REQUEST_DONE(state) {
      if (state.requestsPending >= 1) {
        state.requestsPending--
      }

      if (state.requestsPending <= 0) {
        this.commit('app/TOGGLE_OVERLAY', false)
      }
    },
    SET_GLOBAL_MESSAGE(state, { message, type }) {
      state.globalMessage = message
      state.globalMessageType = type
    }
  },

  actions: {},
}
