import Vue from 'vue'
import VueRouter from 'vue-router'
import vueCompositionApi, {computed} from "@vue/composition-api";
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import {getUserData, isUserLoggedIn} from "@/auth/utils";
import {canNavigate} from "@/libs/acl/routeProtection";
const { i18n: i18nFunc } = useI18nUtils();





Vue.use(VueRouter)
Vue.use(vueCompositionApi)

function i18nT(str) {
	return i18nFunc(str);
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {x: 0, y: 0}
	},
	routes: [
		{
			path: '/',
			name: 'dashboard-manager',
			component: () => import('@/views/Home.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(()=> i18nT(`Bureau`)),
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Bureau`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/properties/property/:id',
			name: 'property-public',
			component: () => import('@/views/landlord/Properties/Units/UnitDetailPublic.vue'),
			meta: {
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Bien`)),
						active: true,
					},
				],
				layout: 'full'
			},
		},
		{
			path: '/clients',
			name: 'clients',
			component: () => import('@/views/ComingSoon.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(()=> i18nT(`Clients`)),
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Clients`)),
						active: true,
					},
				],
			},
			children: [
				{
					path: 'dashboard',
					name: 'dashboard-landlord',
					component: () => import('@/views/ComingSoon.vue'),
					meta: {
						roles: ['landlord'],
						pageTitle: computed(()=> i18nT(`Coming Soon`)),
						breadcrumb: [
							{
								text: computed(()=> i18nT(`Landlords Dashboard`)),
								active: true,
							},
						],
					},
				},
			],
		},

		{
			path: '/mandates',
			name: 'mandates',
			component: () => import('@/views/ComingSoon.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(()=> i18nT(`Mandats`)),
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Mandats`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/biens',
			name: 'biens',
			component: () => import('@/views/ComingSoon.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(()=> i18nT(`Biens`)),
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Biens`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/properties',
			name: 'properties',
			component: () => import('@/views/landlord/Properties/Units/UnitsList.vue'),
			meta: {
				roles: ['landlord'],
				// pageTitle: computed(()=> i18nT(`Lots`)),
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Lots`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/properties/:id/view',
			name: 'view-property',
			component: () => import('@/views/landlord/Properties/Units/UnitDetail.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'properties',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Lots`)),
						// text: 'Tenants',
						to: '/landlord/properties',
					},
					{
						text: computed(()=> i18nT(`Lot`)),
						// text: 'Tenant',
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/properties/new/:tab?',
			name: 'create-property',
			component: () => import('@/views/landlord/Properties/Units/EditUnit.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'properties',
				// pageTitle: `Locataire`,
				// pageTitle: computed(() => i18nT(`Immeubles`)),
				breadcrumb: [
					{
						text: computed(() => i18nT(`Lots`)),
						to: '/landlord/properties',
					},
					{
						text: computed(() => i18nT(`Nouveau lot`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/properties/:id/edit/:tab?',
			name: 'edit-property',
			component: () => import('@/views/landlord/Properties/Units/EditUnit.vue'),
			meta: {
				navActiveLink: 'properties',
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Lots`)),
						to: '/landlord/properties',
					},
					{
						text: computed(()=>(`Modifier le lot`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/buildings',
			name: 'buildings',
			component: () => import('@/views/landlord/Properties/Buildings/BuildingsList.vue'),
			meta: {
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Immeubles`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/buildings/:id/view',
			name: 'view-building',
			component: () => import('@/views/landlord/Properties/Buildings/BuildingDetail.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'buildings',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Immeubles`)),
						// text: 'Tenants',
						to: '/landlord/buildings',
					},
					{
						text: computed(()=> i18nT(`Immeuble`)),
						// text: 'Tenant',
						active: true,
					},
				],
			},
		},


		{
			path: '/landlord/buildings/:id/edit/:tab?',
			name: 'edit-building',
			component: () => import('@/views/landlord/Properties/Buildings/EditBuilding.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'buildings',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Immeubles`)),
						to: '/landlord/buildings',
					},
					{
						text: computed(()=>(`Modifier le immeuble`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/buildings/new/:tab?',
			name: 'create-building',
			component: () => import('@/views/landlord/Properties/Buildings/EditBuilding.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'buildings',
				pageTitle: `Locataire`,
				// pageTitle: computed(() => i18nT(`Immeubles`)),
				breadcrumb: [
					{
						text: computed(() => i18nT(`Immeubles`)),
						to: '/landlord/buildings',
					},
					{
						text: computed(() => i18nT(`Nouveau immeuble`)),
						active: true,
					},
				],
			},
		},
		
		{
			path: '/landlord/tenants',
			name: 'tenants',
			component: () => import('@/views/landlord/Tenants/TenantsList.vue'),
			meta: {
				roles: ['landlord'],
				// pageTitle: computed(()=> i18nT(`Locataires`)),
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Locataires`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/tenants/:id/view',
			name: 'view-tenant',
			component: () => import('@/views/landlord/Tenants/TenantDetail.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'tenants',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Locataires`)),
						// text: 'Tenants',
						to: '/landlord/tenants',
					},
					{
						text: computed(()=> i18nT(`Locataire`)),
						// text: 'Tenant',
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/tenants/:id/edit/:tab?',
			name: 'edit-tenant',
			component: () => import('@/views/landlord/Tenants/EditTenant.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'tenants',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Locataires`)),
						// text: 'Locataires',
						to: '/landlord/tenants',
					},
					// {
					// 	// text: computed(()=> i18nT(`Tenant List`)),
					// 	text: 'Tenant list',
					// 	to: '/locataires',
					// },
					{
						text: computed(()=>(`Modifier le locataire`)),
						// text: 'Locataire',
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/tenants/new/:tab?',
			name: 'create-tenant',
			component: () => import('@/views/landlord/Tenants/EditTenant.vue'),
			beforeEnter: (to, from, next) => {
				to.meta.breadcrumb = [
					{
						text: computed(() => i18nT(`Locataires`)),
						to: '/landlord/tenants',
					},
					{
						text: computed(() => i18nT(`Nouveau locataire`)),
						active: true,
					},
				],

					next();
			},
			meta: {
				roles: ['landlord'],
				module: 'locataires',
				mode: 'write',
				navActiveLink: 'tenants',
				pageTitle: `Locataire`,
				// pageTitle: computed(() => i18nT(`Tenant`)),
				breadcrumb: [
					{
						text: computed(() => i18nT(`Locataires`)),
						to: '/locataires',
					},
					{
						text: computed(() => i18nT(`Nouveau locataire`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/tenants/import',
			name: 'import-tenants',
			component: () => import('@/views/landlord/Tenants/TenantImport.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'tenants',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Locataires`)),
						to: '/tenants',
					},
					// {
					// 	// text: computed(()=> i18nT(`Tenant List`)),
					// 	text: 'Tenant list',
					// 	to: '/locataires',
					// },
					{
						text: computed(()=> i18nT(`Importer`)),
						// text: 'Locataire',
						active: true,
					},
				],
			},
		},

		{
			path: '/landlord/leases',
			name: 'leases',
			component: () => import('@/views/landlord/Tenancies/TenanciesList.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: 'Locations',
				breadcrumb: [
					{
						text: computed(() => i18nT(`Locations`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/leases/:id/view',
			name: 'view-lease',
			component: () => import('@/views/landlord/Tenancies/TenancyDetail.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'leases',
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Locations`)),
						to: '/landlord/leases',
					},
					{
						text: computed(()=> i18nT(`Location`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/leases/new/:tab?',
			name: 'create-lease',
			component: () => import('@/views/landlord/Tenancies/EditTenancy.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'leases',
				breadcrumb: [
					{
						text: computed(() => i18nT(`Locations`)),
						to: '/landlord/leases',
					},
					{
						text: computed(() => i18nT(`Nouvelle location`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/leases/:id/edit/:tab?',
			name: 'edit-lease',
			component: () => import('@/views/landlord/Tenancies/EditTenancy.vue'),
			meta: {
				navActiveLink: 'leases',
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(()=> i18nT(`Locations`)),
						to: '/landlord/leases',
					},
					{
						text: computed(()=>(`Modifier la location`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/leases/handovers',
			name: 'handovers',
			component: () => import('@/views/landlord/Inventories/InventoriesList.vue'),
			meta: {
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(() => i18nT(`Etat des lieux`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/leases/handover',
			name: 'create-handover',
			component: () => import('@/views/landlord/Inventories/EditInventory.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'handovers',
				breadcrumb: [
					{
						text: computed(() => i18nT(`Etat des lieux`)),
						to: '/landlord/leases/handovers',
					},
					{
						text: computed(() => i18nT(`Nouvel état des lieux`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/landlord/leases/handover/HandoverID=:id?',
			name: 'edit-handover',
			component: () => import('@/views/landlord/Inventories/EditInventory.vue'),
			meta: {
				roles: ['landlord'],
				navActiveLink: 'handovers',
				breadcrumb: [
					{
						text: computed(() => i18nT(`Etat des lieux`)),
						to: '/landlord/leases/handovers',
					},
					{
						text: computed(() => i18nT(`Nouvel état des lieux`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/reservations',
			name: 'reservations',
			component: () => import('@/views/ComingSoon.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(() => i18nT(`Reservations`)),
				breadcrumb: [
					{
						text: computed(() => i18nT(`Reservations`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/checkout',
			name: 'checkout',
			component: () => import('@/views/ComingSoon.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(() => i18nT(`Etat des lieux`)),
				breadcrumb: [
					{
						text: computed(() => i18nT(`Etat des lieux`)),
						active: true,
					},
				],
			},
		},
		{
			path: '/finances-home',
			name: 'finances-home',
			component: () => import('@/views/ComingSoon.vue'),
			meta: {
				roles: ['landlord'],
				pageTitle: computed(() => i18nT(`Finances`)),
				breadcrumb: [
					{
						text: computed(() => i18nT(`Finances`)),
						active: true,
					},
				],
			},
		},

		{
			path: '/trash',
			name: 'trash',
			component: () => import('@/views/landlord/Trash/TrashList.vue'),
			meta: {
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(() => i18nT(`Corbeille`)),
						active: true,
					},
				],
			},
		},


		{
			path: '/landlord/profile/edit',
			name: 'landlord-profile',
			component: () => import('@/views/landlord/Profile/LandlordProfileVertical.vue'),
			meta: {
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(() => i18nT(`Mon compte`)),
						active: true,
					}
				]
			}
		},
		{
			path: '/landlord/profile/settings',
			name: 'landlord-settings',
			component: () => import('@/views/landlord/Profile/LandlordSettingsVertical.vue'),
			meta: {
				roles: ['landlord'],
				breadcrumb: [
					{
						text: computed(() => i18nT(`Mon compte`)),
						to: '/landlord/profile/edit', // TODO change to /profile when ready
					},
					{
						text: computed(() => i18nT(`Réglages divers`)),
						active: true,
					}
				]
			}
		},
		{
			path: '/login',
			name: 'auth-login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'full',
				redirectIfLoggedIn: true,
			},
		},
		{
			path: '/register',
			name: 'auth-register',
			component: () =>
				import ('@/views/Register.vue'),
			meta: {
				layout: 'full',
				redirectIfLoggedIn: true,
			},
		},
		{
			path: '/forgotten',
			name: 'auth-forgot-password',
			component: () =>
				import ('@/views/ForgotPassword.vue'),
			meta: {
				layout: 'full',
				redirectIfLoggedIn: true,
			},
		},
		{
			path: '/forgotten/change',
			name: 'auth-forgot-password-change',
			component: () =>
				import ('@/views/ChangeForgottenPassword.vue'),
			meta: {
				layout: 'full',
			}
		},
		{
			path: '/activate',
			name: 'auth-activate',
			component: () =>
				import ('@/views/Activate.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/not-authorized',
			name: 'not-authorized',
			component: () => import('@/views/NotAuthorized.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/error-404',
			name: 'error-404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '*',
			redirect: 'error-404',
		},
	],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

router.beforeEach((to,_,next)=>{
	const isLoggedIn = isUserLoggedIn();
	// console.log('isLoggedIn', isLoggedIn);

	if (!canNavigate(to)) {
		console.log('Not authorized');
		// Can't navigate because not logged-in -> go to login
		if (!isLoggedIn) {
			return next({name: 'auth-login'});
		}

		// If logged but not authorized, redirect to standard page
		return next({name: 'not-authorized'});
	}

	if (to.meta.redirectIfLoggedIn && isLoggedIn) {
		console.log('Already logged in')
		const userData = getUserData();
		next({name: 'dashboard-manager'});
	}
	return next();

})



export default router
