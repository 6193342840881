import Vue from 'vue';
import CrispChat from '@dansmaculotte/vue-crisp-chat';
import VueSignaturePad from 'vue-signature-pad';



const initializeAdditionalExtensions = () => {

	Vue.use(CrispChat, {
		websiteId: 'd5178d94-66d5-4d4a-889b-a2a408c505ff',
		disabled: false,
		hideOnLoad: false
	});

	Vue.use(VueSignaturePad);

}

export default initializeAdditionalExtensions;